import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import * as axios from "../Axios/Axios";
import { useNavigate } from "react-router-dom";

const style = {
    label: {
        color: "#555",
        marginBottom: "5px",
        marginTop: "10px",
    },
    cardTitle: {
        color: "#555",
        fontSize: "30px",
        fontWeight: "700",
    },
    title: {
        color: "#555",
        fontSize: "20px",
        fontWeight: "700",
    },
    radio: {
        marginLeft: "20px",
        height: "12px",
    },
};

const SignUp = () => {
    const navigate = useNavigate();
    const [attachment, setAttachment] = useState(null);
    const [haveBusiness, setHaveBusiness] = useState(false);

    const handleChange = (e, formProps) => {
        if (e.target.name === "haveBusiness") {
            if (e.target.value == "Yes") setHaveBusiness(true);
            else {
                setHaveBusiness(false);
                formProps.setFieldValue("businessType", e.target.value);
            }
        }
        formProps.setFieldValue(e.target.name, e.target.value);
    };

    return (
        <div className="r-width" style={{ margin: "auto", marginTop: "60px" }}>
            <h5 className="text-left font-weight-bold">SignUp!</h5>
            <Formik
                enableReinitialize
                initialValues={{}}
                validationSchema={Yup.object({
                    name: Yup.string().required("Required"),
                    username: Yup.string()
                        .required("Required")
                        .matches(
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                            "Provide a valid Email"
                        ),
                    password: Yup.string().required("Provide a password"),
                    confirmPassword: Yup.string()
                        .required("Passwords don't match.")
                        .oneOf(
                            [Yup.ref("password"), null],
                            "Passwords don't match."
                        ),
                })}
                onSubmit={(values) => {
                    const fileInput = document.getElementById("photo");
                    axios.postFormData(
                        "api/account/createUser",
                        values,
                        fileInput.files,
                        (response) => {
                            if (response.data.success) {
                                navigate("/signin");
                            } else {
                                alert(response.data.message);
                            }
                        }
                    );
                }}
            >
                {(formProps) => (
                    <Form noValidate>
                        <div className="form-group">
                            <label style={style.label}>Full Name</label>
                            <input
                                name="name"
                                type="name"
                                required
                                className="form-control"
                                onChange={(e) => handleChange(e, formProps)}
                            />
                            <span className="text-danger">
                                {formProps.errors.name}
                            </span>
                        </div>

                        <div className="form-group">
                            <label style={style.label}>Email</label>
                            <input
                                name="username"
                                type="email"
                                required
                                className="form-control"
                                onChange={(e) => handleChange(e, formProps)}
                            />
                            <span className="text-danger">
                                {formProps.errors.username}
                            </span>
                        </div>
                        <div className="form-group">
                            <label style={style.label}>Photo</label>
                            <input
                                id="photo"
                                name="photo"
                                type="file"
                                required
                                className="form-control"
                                onChange={(e) => {
                                    setAttachment(e.target.files[0]);
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label style={style.label}>Password</label>
                            <input
                                name="password"
                                type="password"
                                required
                                className="form-control"
                                onChange={(e) => handleChange(e, formProps)}
                            />
                            <span className="text-danger">
                                {formProps.errors.password}
                            </span>
                        </div>
                        <div className="form-group">
                            <label style={style.label}>Confirm Password</label>
                            <input
                                name="confirmPassword"
                                type="password"
                                required
                                className="form-control"
                                onChange={(e) => handleChange(e, formProps)}
                            />
                            <span className="text-danger">
                                {formProps.errors.confirmPassword}
                            </span>
                        </div>

                        <div className="form-group mb-2">
                            <label>Do you have a Business?</label> {"  "}
                            <input
                                style={style.radio}
                                type="radio"
                                name="haveBusiness"
                                value="No"
                                onChange={(e) => handleChange(e, formProps)}
                            />{" "}
                            No
                            <input
                                style={style.radio}
                                type="radio"
                                name="haveBusiness"
                                value="Yes"
                                onChange={(e) => handleChange(e, formProps)}
                            />{" "}
                            Yes
                        </div>
                        <div
                            style={{
                                display: haveBusiness ? "block" : "none",
                            }}
                        >
                            <div className="form-group">
                                <label style={style.label}>Name of Store</label>
                                <input
                                    name="storeName"
                                    type="storeName"
                                    required
                                    className="form-control"
                                    onChange={(e) => handleChange(e, formProps)}
                                />
                                <span className="text-danger">
                                    {formProps.errors.storeName}
                                </span>
                            </div>

                            <div className="form-group">
                                <label style={style.label}>Store Address</label>
                                <input
                                    name="storeAddress"
                                    type="storeAddress"
                                    required
                                    className="form-control"
                                    onChange={(e) => handleChange(e, formProps)}
                                />
                                <span className="text-danger">
                                    {formProps.errors.name}
                                </span>
                            </div>

                            <div className="form-group">
                                <label style={style.label}>Web Link</label>
                                <input
                                    name="webLink"
                                    type="webLink"
                                    required
                                    className="form-control"
                                    onChange={(e) => handleChange(e, formProps)}
                                />
                                <span className="text-danger">
                                    {formProps.errors.name}
                                </span>
                            </div>

                            <div className="form-group">
                                <label style={style.label}>
                                    What kind of Business?
                                </label>
                                <select
                                    className="form-control"
                                    id="businessType"
                                    name="businessType"
                                    onChange={(e) => handleChange(e, formProps)}
                                >
                                    <option value="Apperal">Apperal</option>
                                    <option value="Sporting Goods">
                                        Sporting Goods
                                    </option>
                                    <option value="Furtinure">Furtinure</option>
                                    <option value="Other Tech">
                                        Other Tech
                                    </option>
                                </select>
                            </div>
                        </div>
                        <input
                            type="submit"
                            className="form-control btn btn-dark btn-sm mt-3 bg-dark text-white"
                            value="Sign Up"
                        />
                    </Form>
                )}
            </Formik>

            <p>
                Already have an Account?{" "}
                <a href="/signin" className="text-dark">
                    Sign In
                </a>
            </p>

            {/* <Grid
                container
                justifyContent="flex-end"
            >
                <Grid item>
                    <Button
                        variant="contained"
                        className="br-20"
                        color="primary"
                    >
                        Sign In
                    </Button>
                </Grid>
            </Grid> */}
        </div>
    );
};

export default SignUp;
