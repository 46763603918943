import React, { useEffect, useState } from "react";
import "./Home.css";
import PostCard from "../Components/PostCard";
// import { loadState, removeState } from "../Utils/storage";
// import * as keys from "../Utils/keys";
import { v4 as uuidv4 } from "uuid";
// import PostModal from "../Components/PostModal";
import { SearchOutlined } from "@mui/icons-material";
import FilterModal from "../Components/FilterModal";
import * as axios from "../Axios/Axios";
import ShareModal from "./ShareModal";
import ProductModal from "../Components/ProductModal";

const Home = () => {
  const [products, setProducts] = useState([]);
  const [keywords, setKeyWords] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);

  useEffect(() => {
    axios.get("api/Product", undefined, (response) => {
      setProducts(response.data);
    });
  }, [setProducts]);

  var search = () => {
    axios.get(`api/product/search/${keywords}/share`, undefined, (response) => {
      setProducts(response.data);
    });
  };

  var onChangeInput = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      search();
    } else {
      setKeyWords(e.target.value);
    }
  };

  return (
    <>
      <div className="mt-5">
        <div className="container text-center">
          <form className="nosubmit" onSubmit={(e) => e.preventDefault()}>
            <input
              className="nosubmit"
              type="search"
              placeholder="Search..."
              onKeyUp={(e) => onChangeInput(e)}
            />
          </form>
          <SearchOutlined
            style={{
              marginLeft: "10px",
              marginRight: "5px",
              cursor: "pointer",
            }}
            onClick={() => search()}
          />
          {/* <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#filterModal"
                        style={{ textDecoration: "none" }}
                    >
                        <FilterAltOutlined style={{ cursor: "pointer" }} />
                    </a> */}
          {/* <a href='/create-post' style={{ textDecoration: 'none' }}><button className='plus-button'>+</button></a> */}
        </div>
      </div>
      <div className="col-12">
        <div
          className="mt-1"
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignContent: "space-between",
          }}
        >
          {products?.map((post) => {
            return (
              <div style={{ marginRight: "10px" }} key={uuidv4()}>
                {/* <div onClick={() => setSelectedPost(post)}> */}
                {/* <a href={`/product-detail/${post.id}`}> */}
                <PostCard
                  post={post}
                  setSelectedProduct={setSelectedProduct}
                  setOpenModal={setOpenModal}
                  setOpenViewModal={setOpenViewModal}
                />
                {/* </a> */}
                {/* </div> */}
              </div>
            );
          })}
        </div>

        {/* <PostModal post={selectedPost} /> */}
        <FilterModal />
        <ProductModal
          openModal={openViewModal}
          setOpenModal={setOpenViewModal}
          post={selectedProduct}
        />
        <ShareModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          selectedProduct={selectedProduct}
        />
        
      </div>
    </>
  );
};

export default Home;
