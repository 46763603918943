import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Rating, Slider } from '@mui/material';
import { Button, TextField, Typography, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { Form, Formik } from 'formik';
import { StarBorder } from '@mui/icons-material';

const FilterModal = () => {

    const handleChange = (e, formProps) => {
        formProps.setFieldValue(e.target.name, e.target.value);
    }

    return (
        <div className="modal fade" id="filterModal" aria-labelledby="filterModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <Formik
                            enableReinitialize
                            initialValues={{}}
                            onSubmit={(values) => {
                                console.log(values);
                            }}
                        >
                            {

                                formProps => (
                                    <Form noValidate>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox />} label="Only Verified" />
                                        </FormGroup>
                                        <Typography align="left" variant="body2">By Rating
                                            <Rating
                                                name="rating"
                                                precision={0.5}
                                                emptyIcon={<StarBorder fontSize="inherit" />}
                                                style={{ marginLeft: '10px', marginTop: '5px', marginBottom: '5px' }}
                                            // onChange={e => handleRating(e.target.value)}
                                            />
                                        </Typography>
                                        <Typography align="left" variant="body2">Price Range</Typography>
                                        <Slider
                                            aria-label="Temperature"
                                            defaultValue={50000}
                                            // getAriaValueText={valuetext}
                                            valueLabelDisplay="auto"
                                            step={10000}
                                            marks
                                            min={1000}
                                            max={300000}
                                        />
                                        <TextField
                                            name="location"
                                            label="By Brand"
                                            // variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            onChange={(e) => handleChange(e, formProps)}
                                            value={formProps.values.location}
                                            InputLabelProps={{
                                                shrink: true,
                                                className: "font-700"
                                            }}
                                            error={formProps.errors.location ? true : false}
                                            helperText={formProps.errors.location}
                                        />
                                        
                                        <TextField
                                            name="location"
                                            label="By Location"
                                            // variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            onChange={(e) => handleChange(e, formProps)}
                                            value={formProps.values.location}
                                            InputLabelProps={{
                                                shrink: true,
                                                className: "font-700"
                                            }}
                                            error={formProps.errors.location ? true : false}
                                            helperText={formProps.errors.location}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="br-20 mb-20 mt-3"
                                            type="button"
                                            fullWidth
                                        >
                                            Search
                                        </Button>
                                    </Form>
                                )
                            }
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterModal;