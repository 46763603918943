import React, { useEffect, useState } from "react";
import "./Cart.css";
import * as axios from "../Axios/Axios";
import { useParams } from "react-router-dom";

// import { loadState } from "../Utils/storage";
// import * as keys from "../Utils/keys";
import { v4 as uuidv4 } from "uuid";

const Checkout = () => {
    const { id } = useParams();
    const { ad } = useParams();
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        // var ids = loadState(keys.CartProductIds);
        // var posts = loadState(keys.Posts);
        // var products2 = [];
        // ids.forEach((id) => {
        //   products2.push(posts.find((e) => e.id == id));
        // });
        console.log(ad);
        if (ad == false) {
            axios.get(`api/AdProduct/${id}`, undefined, (response) => {
                if (response.data != null) setProducts([response.data]);
            });
        } else {
            axios.get(`api/Product/${id}`, undefined, (response) => {
                if (response.data != null) setProducts([response.data]);
            });
        }

        // setProducts(products2);
    }, []);

    useEffect(() => {
        console.log(products);
        if (products.length > 0 && products != null) {
            setTotal(products.reduce((a, b) => a + b.price * (b.qty ==- 0? 1: b.qty), 0));
        }
    }, [products]);

    var changeQty = (e, id) => {
        var i = products.findIndex((e) => e.id == id);
        var arr = [...products];
        arr[i].qty = e.target.value;
        setProducts(arr);
    };

    var RemoveItem = (product) => {
        var arr = [...products];
        var index = products.findIndex((e) => e.id == product.id);
        arr.splice(index, 1);
        setProducts(arr);
    };

    return (
        <>
            <section className="mt-5">
                <div className="container">
                    <h3 className="font-weight-bold">CHECKOUT</h3>
                    <p>
                        Please enter your details below to complete your
                        purchase.
                    </p>
                    <div className="row mt-3">
                        <div className="col-md-4 col-sm-12">
                            <div className="card">
                                <div
                                    className="card-header p-0"
                                    id="headingOne"
                                >
                                    <h5 className="mb-0">
                                        <a
                                            className="btn w-100 text-dark no-collapsable"
                                            data-toggle="collapse"
                                            data-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            <h4 className="text-m m-0 text-left text-capitalize">
                                                <i className="icon-location rounded-circle bg-button-fill white-text box-round box-round-checkout d-inline-block text-center h2 mr-3"></i>
                                                <strong className="text-danger">
                                                    Shipping Address
                                                </strong>
                                            </h4>
                                        </a>
                                    </h5>
                                </div>
                                <div className="">
                                    <div className="card-body p-0">
                                        <div className="p-3 bg-light">
                                            <div className="shipping-address">
                                                <div className="form-row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="name">
                                                                <strong className="text-s">
                                                                    Full Name
                                                                </strong>{" "}
                                                                <span className="text-danger text-s">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                className="form-control"
                                                                id="name"
                                                                placeholder="Name"
                                                                value=""
                                                            />
                                                            <div className="invalid-feedback text-first-cap"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-f--s">
                                                            <label htmlFor="name">
                                                                <strong className="text-s">
                                                                    Address
                                                                </strong>{" "}
                                                                <span className="text-danger text-s">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <textarea
                                                                name="address"
                                                                id="address"
                                                                className="form-control"
                                                                placeholder="Address"
                                                            ></textarea>
                                                            <div className="invalid-feedback text-first-cap"></div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="district">
                                                                    <strong className="text-s">
                                                                        District/City
                                                                    </strong>{" "}
                                                                    <span className="text-danger text-s">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <select
                                                                    name="district_city"
                                                                    data-selected=""
                                                                    id="district"
                                                                    className="form-control select2 district-city select2-hidden-accessible"
                                                                    tabindex="-1"
                                                                    aria-hidden="true"
                                                                >
                                                                    <option
                                                                        value=""
                                                                        selected="selected"
                                                                    >
                                                                        Select a
                                                                        District
                                                                    </option>
                                                                    <option value="Bagherhat">
                                                                        Bagherhat
                                                                    </option>
                                                                    <option value="Bandarban">
                                                                        Bandarban
                                                                    </option>
                                                                    <option value="Barguna">
                                                                        Barguna
                                                                    </option>
                                                                    <option value="Barishal">
                                                                        Barishal
                                                                    </option>
                                                                    <option value="Bhola">
                                                                        Bhola
                                                                    </option>
                                                                    <option value="Bogra">
                                                                        Bogra
                                                                    </option>
                                                                    <option value="Brahmanbaria">
                                                                        Brahmanbaria
                                                                    </option>
                                                                    <option value="Chandpur">
                                                                        Chandpur
                                                                    </option>
                                                                    <option value="Chapinawabganj">
                                                                        Chapinawabganj
                                                                    </option>
                                                                </select>
                                                                <span
                                                                    className="select2 select2-container select2-container--default"
                                                                    dir="ltr"
                                                                    style={{
                                                                        width: "156.188px",
                                                                    }}
                                                                >
                                                                    <span className="selection">
                                                                        <span
                                                                            className="select2-selection select2-selection--single"
                                                                            role="combobox"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                            tabindex="0"
                                                                            aria-labelledby="select2-district-container"
                                                                        >
                                                                            <span
                                                                                className="select2-selection__rendered"
                                                                                id="select2-district-container"
                                                                                title="Select a District"
                                                                            ></span>
                                                                            <span
                                                                                className="select2-selection__arrow"
                                                                                role="presentation"
                                                                            >
                                                                                <b role="presentation"></b>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    <span
                                                                        className="dropdown-wrapper"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                </span>
                                                                <div className="invalid-feedback text-first-cap"></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="zip">
                                                                    <strong className="text-s">
                                                                        ZIP/Postal
                                                                        Code
                                                                    </strong>{" "}
                                                                    <span className="text-danger text-s">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <select
                                                                    name="zip"
                                                                    data-test="sdfsdf"
                                                                    data-selected=""
                                                                    id="zip"
                                                                    className="form-control select2 zip select2-hidden-accessible"
                                                                    tabindex="-1"
                                                                    aria-hidden="true"
                                                                ></select>
                                                                <span
                                                                    className="select2 select2-container select2-container--default"
                                                                    dir="ltr"
                                                                    style={{
                                                                        width: "156.188px",
                                                                    }}
                                                                >
                                                                    <span className="selection">
                                                                        <span
                                                                            className="select2-selection select2-selection--single"
                                                                            role="combobox"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false"
                                                                            tabindex="0"
                                                                            aria-labelledby="select2-zip-container"
                                                                        >
                                                                            <span
                                                                                className="select2-selection__rendered"
                                                                                id="select2-zip-container"
                                                                            ></span>
                                                                            <span
                                                                                className="select2-selection__arrow"
                                                                                role="presentation"
                                                                            >
                                                                                <b role="presentation"></b>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    <span
                                                                        className="dropdown-wrapper"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                </span>
                                                                <div className="invalid-feedback text-first-cap"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="mobile">
                                                                    <strong className="text-s">
                                                                        Mobile
                                                                        Number{" "}
                                                                    </strong>{" "}
                                                                    <span className="text-danger text-s">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    type="tel"
                                                                    name="mobile"
                                                                    className="form-control"
                                                                    id="mobile"
                                                                    placeholder="Phone Number"
                                                                    value=""
                                                                />
                                                                <div className="invalid-feedback text-first-cap"></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group ">
                                                                <label htmlFor="email-address">
                                                                    <strong className="text-s">
                                                                        Email
                                                                    </strong>{" "}
                                                                    <span className="text-danger text-s">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    type="email"
                                                                    name="email"
                                                                    className="form-control"
                                                                    id="mail-address"
                                                                    placeholder="Email "
                                                                    value=""
                                                                />
                                                                <div className="invalid-feedback text-first-cap"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 mb-3 px-1 px-lg-3">
                            <div className="p-3 bg-light">
                                <div className="shipping-method pb-4">
                                    <h4 className="text-m">
                                        <i className="icon-truck rounded-circle bg-button-fill white-text box-round box-round-checkout d-inline-block text-center h2 mr-3"></i>
                                        <strong className="text-danger">
                                            Shipping Method
                                        </strong>
                                    </h4>
                                    <div className="row mb-2">
                                        <div className="col-lg-12 custom-radio">
                                            <input
                                                type="radio"
                                                className="mt-1 custom-radio-input shipping-method-radio ml-3  col-1"
                                                name="shipping_method"
                                                id="standard"
                                                value="standard"
                                                checked=""
                                            />
                                            <label
                                                htmlFor="standard"
                                                className="col-lg-10 col-9 ml-3 pr-0 pr-lg-3"
                                            >
                                                <div className="row">
                                                    <div className="col-lg-8 col-8 pr-0 align-self-center">
                                                        <p className="mb-0 shipping-title">
                                                            Inside Dhaka: Next
                                                            day inside Dhaka
                                                            metro
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-4 col-4 col px-lg-0 text-right align-self-center shipping-price"></div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="shipping-method mt-5">
                                    <h4 className="text-m">
                                        <i className="icon-bank-card rounded-circle bg-button-fill white-text box-round box-round-checkout d-inline-block text-center h2 mr-3"></i>
                                        <strong className="text-danger">
                                            Payment Method
                                        </strong>
                                    </h4>
                                    <div className="row mb-2">
                                        <div className="col-lg-12 custom-radio ml-3 mb-2">
                                            <input
                                                type="radio"
                                                className="col-lg-1 col-1 custom-radio-input"
                                                name="payment_method"
                                                value="cards"
                                                id="cards"
                                            />
                                            <label
                                                htmlFor="cards"
                                                className="col-lg-11 col-11 d-block float-right pl-4"
                                            >
                                                Debit &amp; Cards / Online
                                                Payments
                                            </label>
                                        </div>
                                        <div className="col-lg-12 custom-radio ml-3 mb-2">
                                            <input
                                                type="radio"
                                                className="col-lg-1 col-1 custom-radio-input"
                                                name="payment_method"
                                                value="card_on_delivery"
                                                id="card_on_delivery"
                                            />
                                            <label
                                                htmlFor="card_on_delivery"
                                                className="col-lg-11 col-11 d-block float-right pl-4"
                                            >
                                                Card on Delivery
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-lg-12 custom-radio ml-3 mb-2 d-none"
                                    id="card_on_delivery_cards_list"
                                >
                                    <label
                                        className="d-block"
                                        style={{ paddingBottom: "5px" }}
                                    >
                                        Select your card type
                                    </label>
                                    <select
                                        className="card-on-delivery-cards select2-hidden-accessible"
                                        name="card_on_delivery_card_id"
                                        tabindex="-1"
                                        aria-hidden="true"
                                    >
                                        <option value=""></option>
                                        <option
                                            value="1"
                                            data-img="https://i7.pngguru.com/preview/117/675/830/mastercard-credit-card-american-express-visa-debit-card-mastercard-thumbnail.jpg"
                                        >
                                            Visa/Master
                                        </option>
                                        <option
                                            value="2"
                                            data-img="https://img.favpng.com/6/7/17/logo-american-express-membership-rewards-organization-brand-png-favpng-VbV4yjidGTwS2DEqwaGpPhrWn.jpg"
                                        >
                                            Amex
                                        </option>
                                        <option
                                            value="3"
                                            data-img="https://is4-ssl.mzstatic.com/image/thumb/Purple123/v4/e2/ff/3e/e2ff3ed2-091b-a358-21f1-d334624a4c2a/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-85-220.png/246x0w.png"
                                        >
                                            Nexus
                                        </option>
                                    </select>
                                    <span
                                        className="select2 select2-container select2-container--default"
                                        dir="ltr"
                                        style={{ width: "100%" }}
                                    >
                                        <span className="selection">
                                            <span
                                                className="select2-selection select2-selection--single"
                                                role="combobox"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                tabindex="0"
                                                aria-labelledby="select2-card_on_delivery_card_id-eg-container"
                                            >
                                                <span
                                                    className="select2-selection__rendered"
                                                    id="select2-card_on_delivery_card_id-eg-container"
                                                >
                                                    <span className="select2-selection__placeholder">
                                                        Select Your Card Type
                                                    </span>
                                                </span>
                                                <span
                                                    className="select2-selection__arrow"
                                                    role="presentation"
                                                >
                                                    <b role="presentation"></b>
                                                </span>
                                            </span>
                                        </span>
                                        <span
                                            className="dropdown-wrapper"
                                            aria-hidden="true"
                                        ></span>
                                    </span>
                                </div>
                                <div className="pt-3">
                                    <p>Order Note (optional)</p>
                                    <div className="input-group mb-4">
                                        <textarea
                                            name="order_note"
                                            className="form-control"
                                            placeholder="Order note (Max 128 characters)"
                                            maxLength="128"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="cart">
                                <div className="table-responsive">
                                    <table className="table">
                                        <tbody>
                                            {products &&
                                                products.map((product) => (
                                                    <tr key={uuidv4()}>
                                                        <td width={120}>
                                                            <div className="main">
                                                                <div className="d-flex">
                                                                    <img
                                                                        src={
                                                                            product?.image
                                                                        }
                                                                        alt=""
                                                                        style={{
                                                                            width: "80px",
                                                                            height: "80px",
                                                                            marginRight:
                                                                                "10px",
                                                                        }}
                                                                    />
                                                                    {
                                                                        product?.brand
                                                                    }{" "}
                                                                    -{" "}
                                                                    {
                                                                        product?.model
                                                                    }
                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="text-danger">
                                                                X
                                                                {(product &&
                                                                    product.qty != 0)? product.qty:
                                                                    1}
                                                            </div>
                                                        </td>
                                                        <td className="text-end">
                                                            <h6>
                                                                $
                                                                {(
                                                                    product?.price *
                                                                    (product?.qty == 0?
                                                                        1:product?.qty)
                                                                )
                                                                    .toString()
                                                                    ?.replace(
                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                        ","
                                                                    )}
                                                            </h6>
                                                        </td>
                                                    </tr>
                                                ))}

                                            <tr className="cart-table-footer text-end">
                                                <td></td>
                                                <td>Total</td>
                                                <td>
                                                    $
                                                    {total
                                                        .toString()
                                                        ?.replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-lg-12 mt-5">
                                    <div className="checkout">
                                        <a
                                            href="#"
                                            className="proceed-btn bg-danger"
                                            onClick={()=>{alert("Payment method not integreted")}}
                                        >
                                            CONFIRM ORDER
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Checkout;
