import React, { useEffect, useState } from "react";
import cover from "../images/cover2.jpeg";
import * as axios from "../Axios/Axios";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

var style = {
    coverPhoto: {
        background: `url(${cover})`,
        width: "100%",
        height: "300px",
    },
    profile: {
        marginTop: "-52px",
    },
    contact: {
        // background: "#f8f8f8",
        padding: "20px",
    },
    name: {
        marginTop: "-52px",
        textAlign: "left",
        marginLeft: "210px",
    },
    editButton: {
        cursor: "pointer",
        fontSize: "15px",
    },
    sellerCard: {
        height: "150px",
        textAlign: "center",
        background: "#DFE7FE",
        borderRadius: "10px",
        cursor: "pointer",
        boxShadow: "#00000000 0px 2px 3px 0px, #00000030 0px 3px 10px 0px",
    },
    postCard: {
        height: "150px",
        textAlign: "center",
        background: "#B6F3F5",
        borderRadius: "10px",
        cursor: "pointer",
        boxShadow: "#00000000 0px 2px 3px 0px, #00000030 0px 3px 10px 0px",
    },
    cardHeader: {
        paddingTop: "10px",
        fontSize: "17px",
        fontWeight: "bold",
    },
    icon: {
        fontSize: "90px",
    },
    radio: {
        marginLeft: "20px",
        height: "12px",
    },
};

const EditProfile = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [haveBusiness, setHaveBusiness] = useState(true);

    useEffect(() => {
        axios.get(`api/User/Profile`, undefined, (response) => {
            setUser(response.data);
            if (response.data.haveBusiness === "Yes") setHaveBusiness(true);
            else setHaveBusiness(false);
        });
    }, []);

    const handleChange = (e, formProps) => {
        if (e.target.name === "haveBusiness") {
            if (e.target.value === "Yes") setHaveBusiness(true);
            else {
                setHaveBusiness(false);
                formProps.setFieldValue("businessType", e.target.value);
            }
        }
        formProps.setFieldValue(e.target.name, e.target.value);
    };

    return (
        <div className="container">
            <div className="offset-3 col-md-6">
                <div className="mt-5">
                    <h3 className="mb-3">Edit Profile</h3>
                    <Formik
                        enableReinitialize
                        initialValues={user}
                        validationSchema={Yup.object({
                            name: Yup.string().required("Required"),
                            username: Yup.string()
                                .required("Required")
                                .matches(
                                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                    "Provide a valid Email"
                                ),
                        })}
                        onSubmit={(values) => {
                            const fileInput = document.getElementById("photo");
                            axios.postFormData(
                                "api/user/editProfile",
                                values,
                                fileInput.files,
                                (response) => {
                                    if (response.data.success) {
                                        navigate("/profile");
                                    } else {
                                        alert(response.data.message);
                                    }
                                }
                            );
                        }}
                    >
                        {(formProps) => (
                            <Form noValidate>
                                <div className="form-group">
                                    <label style={style.label}>Full Name</label>
                                    <input
                                        name="name"
                                        type="name"
                                        required
                                        className="form-control"
                                        defaultValue={user?.name}
                                        onChange={(e) =>
                                            handleChange(e, formProps)
                                        }
                                    />
                                    <span className="text-danger">
                                        {formProps.errors.name}
                                    </span>
                                </div>

                                <div className="form-group">
                                    <label style={style.label}>Email</label>
                                    <input
                                        name="username"
                                        type="email"
                                        required
                                        className="form-control"
                                        defaultValue={user?.username}
                                        onChange={(e) =>
                                            handleChange(e, formProps)
                                        }
                                        disabled={true}
                                    />
                                    <span className="text-danger">
                                        {formProps.errors.username}
                                    </span>
                                </div>
                                {/* <div className="form-group">
                                    <label style={style.label}>
                                        Date of Birth
                                    </label>
                                    <input
                                        name="dob"
                                        type="date"
                                        className="form-control"
                                        defaultValue={user?.dob}
                                        onChange={(e) =>
                                            handleChange(e, formProps)
                                        }
                                    />
                                    <span className="text-danger">
                                        {formProps.errors.dob}
                                    </span>
                                </div> */}
                                {/* <div className="form-group">
                                    <label style={style.label}>Phone</label>
                                    <input
                                        name="phone"
                                        type="text"
                                        className="form-control"
                                        defaultValue={user?.phone}
                                        onChange={(e) =>
                                            handleChange(e, formProps)
                                        }
                                    />
                                    <span className="text-danger">
                                        {formProps.errors.phone}
                                    </span>
                                </div> */}
                                {/* <div className="form-group">
                                    <label style={style.label}>Address</label>
                                    <input
                                        name="address"
                                        type="text"
                                        className="form-control"
                                        defaultValue={user?.address}
                                        onChange={(e) =>
                                            handleChange(e, formProps)
                                        }
                                    />
                                    <span className="text-danger">
                                        {formProps.errors.address}
                                    </span>
                                </div> */}
                                <div className="form-group">
                                    <label style={style.label}>Photo</label>
                                    <input
                                        id="photo"
                                        name="photo"
                                        type="file"
                                        required
                                        className="form-control"
                                        // onChange={(e) => {
                                        //     setAttachment(e.target.files[0]);
                                        // }}
                                    />
                                </div>
                                <div className="form-group mb-2">
                                    <label>Do you have a Business?</label>{" "}
                                    {"  "}
                                    <input
                                        style={style.radio}
                                        type="radio"
                                        name="haveBusiness"
                                        value="No"
                                        defaultChecked={user?.haveBusiness === "No"}
                                        onChange={(e) =>
                                            handleChange(e, formProps)
                                        }
                                    />{" "}
                                    No
                                    <input
                                        style={style.radio}
                                        type="radio"
                                        name="haveBusiness"
                                        value="Yes"
                                        defaultChecked={user?.haveBusiness === "Yes"}
                                        onChange={(e) =>
                                            handleChange(e, formProps)
                                        }
                                    />{" "}
                                    Yes
                                </div>
                                <div
                                    style={{
                                        display: haveBusiness
                                            ? "block"
                                            : "none",
                                    }}
                                >
                                    <div className="form-group">
                                        <label style={style.label}>
                                            Name of Store
                                        </label>
                                        <input
                                            name="storeName"
                                            type="storeName"
                                            required
                                            className="form-control"
                                            defaultValue={user?.storeName}
                                            onChange={(e) =>
                                                handleChange(e, formProps)
                                            }
                                        />
                                        <span className="text-danger">
                                            {formProps.errors.storeName}
                                        </span>
                                    </div>

                                    <div className="form-group">
                                        <label style={style.label}>
                                            Store Address
                                        </label>
                                        <input
                                            name="storeAddress"
                                            type="storeAddress"
                                            required
                                            className="form-control"
                                            defaultValue={user?.storeAddress}
                                            onChange={(e) =>
                                                handleChange(e, formProps)
                                            }
                                        />
                                        <span className="text-danger">
                                            {formProps.errors.name}
                                        </span>
                                    </div>

                                    <div className="form-group">
                                        <label style={style.label}>
                                            Web Link
                                        </label>
                                        <input
                                            name="webLink"
                                            type="webLink"
                                            required
                                            className="form-control"
                                            defaultValue={user?.webLink}
                                            onChange={(e) =>
                                                handleChange(e, formProps)
                                            }
                                        />
                                        <span className="text-danger">
                                            {formProps.errors.name}
                                        </span>
                                    </div>

                                    <div className="form-group">
                                        <label style={style.label}>
                                            What kind of Business?
                                        </label>
                                        <select
                                            className="form-control"
                                            id="businessType"
                                            name="businessType"
                                            onChange={(e) =>
                                                handleChange(e, formProps)
                                            }
                                        >
                                            <option value="Apperal">
                                                Apperal
                                            </option>
                                            <option value="Sporting Goods">
                                                Sporting Goods
                                            </option>
                                            <option value="Furtinure">
                                                Furtinure
                                            </option>
                                            <option value="Other Tech">
                                                Other Tech
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <input
                                    type="submit"
                                    className="form-control btn btn-dark btn-sm bg-dark text-white mt-3"
                                    value="Save Changes"
                                />
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default EditProfile;
