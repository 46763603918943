import React, { useState } from "react";
import "./Home.css";
import * as axios from "../Axios/Axios";
import { apiUrl } from "../config";

const style = {
  label: {
    color: "#555",
    marginBottom: "5px",
    marginTop: "10px",
  },
  cardTitle: {
    color: "#555",
    fontSize: "30px",
    fontWeight: "700",
  },
  title: {
    color: "#555",
    fontSize: "20px",
    fontWeight: "700",
  },
  radio: {
    marginLeft: "20px",
  },
};

const CreatePost = () => {
  const [disableSubmit, setDisableSubmit] = useState(false);

  var data = {
    name: "",
    url: "",
    category: "",
  };
  const [newPost, setNewPost] = useState(data);
  const [qrCode, setQrCode] = useState(null);

  var onChangeHandler = (event) => {
    var post = newPost;
    post[event.target.name] = event.target.value;
    setNewPost(post);
  };

  const savePost = () => {
    setDisableSubmit(true);

    var data = newPost;
    const fileInput = document.getElementById("image");

    axios.postFormData("api/Product", data, fileInput.files, (response) => {
      if (response.data.success) {
        alert("done");
        setQrCode(response.data.data);
      } else {
        alert(response.data.message);
      }
    });
  };

  return (
    <div className="mt-1">
      <div className="container">
        <div className="row">
          <div
            className="col-md-7 col-sm-12 col-sx-12"
            style={{ marginTop: "30px" }}
          >
            <h4 className="card-title text-center" style={style.cardTitle}>
              Create new Item
            </h4>
            <div className="form-group mb-2">
              <label style={style.label} htmlFor="brand">
                Name of Item
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
            <div className="form-group mb-2" style={{ display: "block" }}>
              <label style={style.label} htmlFor="brand">
                Item URL (it's webpage)
              </label>
              <input
                type="text"
                className="form-control"
                id="url"
                name="url"
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
            <div className="form-group mb-2">
              <label style={style.label} htmlFor="model">
                Item Category
              </label>
              <input
                type="text"
                className="form-control"
                id="category"
                name="category"
                onChange={(e) => onChangeHandler(e)}
              />
            </div>

            <div className="form-group  mb-2">
              <label style={style.label} htmlFor="image">
                Image
              </label>
              <input
                type="file"
                className="form-control"
                id="image"
                name="image"
                multiple
              />
            </div>

            <div className="form-group">
              <input
                type="submit"
                className="mt-2 btn btn-dark text-white bg-dark"
                style={{ marginLeft: "0" }}
                onClick={() => savePost()}
                value="Submit"
                disabled={disableSubmit}
              />

              <button
                type="button"
                className="btn btn-warning"
                onClick={() => {
                    window.location.reload();
                }}
              >
                Reset
              </button>
            </div>
          </div>
          <div className="col-sm-5 col-xs-12" style={{ marginTop: "30px" }}>
            {qrCode != null ? (
              <img
                src={`${apiUrl}/${qrCode}`}
                style={{ width: "300px" }}
                alt=""
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePost;
