import * as storage from './Axios/storage';
import * as keys from './Axios/keys';

export const isAuthorized = (role) => {
    var user = storage.loadState(keys.LOGGED_IN_USER);

    if(user != undefined){
        return true;
    }
    // if(role !== undefined && role !== null) {
    //     return user?.role?.includes(role)
    // }
    return false;
}

  