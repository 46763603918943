import React, { useEffect, useState } from "react";
import "./Cart.css";

import { loadState } from "../Utils/storage";
import * as keys from "../Utils/keys";
import { v4 as uuidv4 } from "uuid";

const Cart = () => {
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    var ids = loadState(keys.CartProductIds);
    var posts = loadState(keys.Posts);
    var products2 = [];
    ids.forEach((id) => {
      products2.push(posts.find((e) => e.id == id));
    });
    setProducts(products2);
  }, []);

  useEffect(() => {
    setTotal(products.reduce((a, b) => a + b.price * (b.qty ?? 1), 0));
  }, [products]);

  var changeQty = (e, id) => {
    var i = products.findIndex((e) => e.id == id);
    var arr = [...products];
    arr[i].qty = e.target.value;
    setProducts(arr);
  };

  var RemoveItem = (product) => {
    var arr = [...products];
    var index = products.findIndex((e) => e.id == product.id);
    arr.splice(index, 1);
    setProducts(arr);
  };

  return (
    <>
      <section className="mt-5">
        <div className="container">
          <div className="cart">
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-dark bg-gray">
                  <tr>
                    <th scope="col" className="text-white">
                      Product
                    </th>
                    <th scope="col" className="text-white">
                      Quantity
                    </th>
                    <th scope="col" className="text-white text-end">
                      Price
                    </th>
                    <th scope="col" className="text-white text-end">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {products &&
                    products.map((product) => (
                      <tr key={uuidv4()}>
                        <td>
                          <div className="main">
                            <div className="d-flex">
                              <img
                                src={product.image}
                                alt=""
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  marginRight: "10px",
                                }}
                              />
                              {product.brand} - {product.model}
                              <br />
                            </div>
                            <p
                              className="d-block"
                              style={{ cursor: "pointer", marginBottom: "0px" }}
                              onClick={() => {
                                RemoveItem(product);
                              }}
                            >
                              Remove
                            </p>
                          </div>
                        </td>
                        <td>
                        <div className="counter">
                            {/* <i className="fas fa-angle-down" on></i> */}
                            <input
                              className="input-number"
                              type="number"
                              value={product.qty ?? 1}
                              min="0"
                              max="10"
                              onChange={(e) => {
                                changeQty(e, product.id);
                              }}
                            />
                            {/* <i className="fas fa-angle-up"></i> */}
                          </div>
                        </td>
                        <td className="text-end">
                          <h6>{product.price}</h6>
                          
                        </td>
                        <td className="text-end">
                          <h6>
                            $
                            {(product.price * (product.qty ?? 1))
                              .toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </h6>
                        </td>
                      </tr>
                    ))}
                  <tr className="cart-table-footer text-end">
                    <td></td>
                    <td></td>
                    <td>Total</td>
                    <td>
                      ${total.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-lg-3 offset-lg-9 mt-5">
              <div className="checkout">
                <a href="#" className="proceed-btn bg-danger">
                  Proceed to Checkout
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
