import { Routes, Route, } from "react-router-dom";
import Chat from './Pages/Chat';
import Layout from './Pages/Layout';
import SignIn from './Pages/SignIn';
import SignUp from './Pages/SignUp';
import Profile from './Pages/Profile';
import LandingPage from './Pages/LandingPage';
import PrivateRoute from './PrivateRoute';
import ProductDetails from "./Pages/ProductDetails";
import Cart from "./Pages/Cart";
import Checkout from "./Pages/Checkout";
import SellerHistory from "./Pages/SellerHistory";
import EditPost from "./Pages/EditPost";
// import AdminDashboard from "./Pages/AdminDashBoard";
// import AdminLayout from "./Pages/AdminLayout";
// import Listing from "./Pages/Listing";
// import Users from "./Pages/Users";
// import FlagProducts from "./Pages/FlagProducts";
import EditProfile from "./Pages/EditProfile";
import Home from './Pages/Home';
import CreatePost from './Pages/CreatePost';
import './App.css'
import BatchCode from "./Pages/BatchCode";
import ScannedItem from "./Pages/ScannedItem";
import History from "./Pages/History";
import Analytics from "./Pages/Analytics";
import BatchDetails from "./Pages/BatchDetails";
import Feedback from "./Pages/Feedback";

function App() {
  return (
    <div className='mzx-w-4xl mx-auto'>
      <Routes>
        <Route path="/signin" element={<Layout component={SignIn} />} />
        <Route path="/signup" element={<Layout component={SignUp} />} />
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/home" element={<Layout component={Home} />}></Route>
        <Route path="/product-detail/:id" element={<Layout component={ProductDetails} />}></Route>
        <Route path="/cart" element={<Layout component={Cart} />}></Route>
        <Route path="/checkout/:id" element={<Layout component={Checkout} />}></Route>
        <Route path="/batch/:id" element={<Layout component={BatchDetails} />}></Route>

        <Route element={<PrivateRoute />}>
          <Route path="/create-post" element={<Layout component={CreatePost} />}></Route>
          <Route path="/edit-post/:id" element={<Layout component={EditPost} />}></Route>
          <Route path="/chat/:id" element={<Layout component={Chat} />}></Route>
          <Route path="/chat" element={<Layout component={Chat} />}></Route>
          {/* <Route path="/advertisements" element={<Layout component={Advertisement} />}></Route> */}
          <Route path="/profile" element={<Layout component={Profile} />}></Route>
          <Route path="/edit-profile" element={<Layout component={EditProfile} />}></Route>
          <Route path="/batches" element={<Layout component={BatchCode} />}></Route>
          <Route path="/analytics" element={<Layout component={Analytics} />}></Route>
          <Route path="/scan-item" element={<Layout component={ScannedItem} />}></Route>
          <Route path="/history" element={<Layout component={History} />}></Route>
          <Route path="/feedback" element={<Layout component={Feedback} />}></Route>

          {/* <Route path="/admin" element={<AdminLayout component={AdminDashboard} />}></Route>
          <Route path="/admin/listing" element={<AdminLayout component={Listing} />}></Route>
          <Route path="/admin/users" element={<AdminLayout component={Users} />}></Route>
          <Route path="/admin/flag-product" element={<AdminLayout component={FlagProducts} />}></Route> */}

        </Route>
        <Route path="/seller-history" element={<Layout component={SellerHistory} />}></Route>
      </Routes>
    </div>
  );
}

export default App;
