import React from "react";

import "./assets/web/assets/mobirise-icons2/mobirise2.css";
import "./assets/bootstrap/css/bootstrap-grid.min.css";
import "./assets/bootstrap/css/bootstrap.min.css";
import "./assets/bootstrap/css/bootstrap-reboot.min.css";
import "./assets/dropdown/css/style.css";
import "./assets/socicon/css/styles.css";
import "./assets/theme/css/style.css";
import "./assets/mobirise/css/mbr-additional.css";
import "./assets/mobirise/css/mbr-additional.css";

import * as storage from "../Axios/storage";
import * as keys from "../Axios/keys";

const LandingPage = () => {
  var user = storage.loadState(keys.LOGGED_IN_USER);
  return (
    <>
      <section
        data-bs-version="5.1"
        className="menu cid-s48OLK6784"
        id="menu1-h"
      >
        <nav className="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
          <div className="container-fluid">
            <div className="navbar-brand">
              <span className="navbar-logo">
                <a href="https://mobiri.se">
                  <img
                    src="assets/images/s-64x119.png"
                    alt="Mobirise Website Builder"
                    style={{ height: "3.8rem" }}
                  />
                </a>
              </span>
              <span className="navbar-caption-wrap">
                <a
                  className="navbar-caption text-black display-4"
                  href="https://mobiri.se"
                >
                  Scansavy
                </a>
              </span>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-bs-toggle="collapse"
              data-target="#navbarSupportedContent"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div className="hamburger">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul
                className="navbar-nav nav-dropdown nav-right"
                data-app-modern-menu="true"
              >
                {/* <li className="nav-item">
                                    <a
                                        className="nav-link link text-black text-primary display-7"
                                        href="/signup"
                                        target="_blank"
                                    >
                                        Join
                                    </a>
                                </li> */}
                <li className="nav-item">
                  {!user ? (
                    <a
                      className="nav-link link text-black text-primary display-7"
                      href="/signIn"
                    >
                      Login
                    </a>
                  ) : (
                    <a
                      className="nav-link link text-black text-primary display-7"
                      href="/signIn"
                    >
                      Logout
                    </a>
                  )}
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link link text-black text-primary display-7"
                    href="/home"
                  >
                    &nbsp;App
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>

      <section
        data-bs-version="5.1"
        className="header14 cid-toFcHa3XCd"
        id="header14-m"
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-5 image-wrapper">
              <img
                src="assets/images/logo-2-593x577.png"
                alt="Mobirise Website Builder"
              />
            </div>
            <div className="col-12 col-md">
              <div className="text-wrapper">
                <h1 className="mbr-section-title mbr-fonts-style mb-3 display-5">
                  <strong>Scansavy</strong>
                </h1>
                <p className="mbr-text mbr-fonts-style display-7">
                  Make and manage custom links at scale!
                  <br />
                </p>
                {/* <div className="mbr-section-btn mt-3">
                  <a className="btn btn-black display-4" href="index.html">
                    Explore all features
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        data-bs-version="5.1"
        className="features4 cid-toFgdqWaQi"
        id="features5-p"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="card-wrapper">
                <div className="row">
                  <div className="col-12 col-md-7">
                    <div className="text-wrapper">
                      <h6 className="card-subtitle mbr-fonts-style mb-2 display-4">
                        Library!
                      </h6>
                      <h5 className="card-title mbr-fonts-style display-5">
                        <strong>Item Library!</strong>
                      </h5>
                      <p className="mbr-text mbr-fonts-style mb-5 display-4">
                        All links are itemized. These are the building blocks
                        for your compound links.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="img-wrapper">
                      <img
                        src="assets/images/mbr-432x432.png"
                        alt="Mobirise Website Builder"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="card-wrapper">
                <div className="row">
                  <div className="col-12 col-md-7">
                    <div className="text-wrapper">
                      <h6 className="card-subtitle mbr-fonts-style mb-2 display-4">
                        Try Now!
                      </h6>
                      <h5 className="card-title mbr-fonts-style display-5">
                        <strong>Compound Links!</strong>
                      </h5>
                      <p className="mbr-text mbr-fonts-style mb-5 display-4">
                        Easily build endless unique links! Simply select any
                        combination of your itemized links from a dropdown menu.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="img-wrapper">
                      <img
                        src="assets/images/mbr-432x433.jpg"
                        alt="Mobirise Website Builder"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        data-bs-version="5.1"
        className="header5 cid-toFc94iKOf"
        id="header5-l"
      >
        <div
          className="mbr-overlay"
          style={{ opacity: "0.5", backgroundColor: "rgb(255, 255, 255)" }}
        ></div>

        <div className="container">
          <div className="row justify-content-end">
            <div className="col-12 col-lg-6">
              <h1 className="mbr-section-title mbr-fonts-style mbr-white mb-3 display-7">
                <strong>Why Us?</strong>
              </h1>

              <p className="mbr-text mbr-fonts-style mbr-white display-7">
                Easy end to end UI.
                <br />
                <br />
                # Automatic QR code generation. <br />
                # Integrate e-commerce anywhere. <br />
                # Give any post it's own unique link! <br />
                <br />
                All of this is easily editable and complete with real-time
                analytics!
                {/* Try Us and See Yourself :<br />
                                <br /># Easy end-to-end UI for businesses and
                                their customers
                                <br /># Integrate
                                brick-and-mortar&nbsp;with&nbsp;e-commerce
                                <br /># A all in one Analytics for all codes
                                <br /> */}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        data-bs-version="5.1"
        className="form9 cid-toForzfFwP"
        id="form9-s"
      >
        <div className="container">
          <div className="mbr-section-head">
            <h3 className="mbr-section-title mbr-fonts-style align-center mb-0 display-5">
              <strong>Subscribe to us</strong>
            </h3>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-lg-8 mx-auto mbr-form" data-form-type="formoid">
              <form
                action="#"
                method="POST"
                className="mbr-form form-with-styler mx-auto"
                data-form-title="Form Name"
              >
                <div className="row">
                  <div
                    hidden="hidden"
                    data-form-alert=""
                    className="alert alert-success col-12"
                  >
                    Thanks for filling out the form!
                  </div>
                  <div
                    hidden="hidden"
                    data-form-alert-danger=""
                    className="alert alert-danger col-12"
                  >
                    Oops...! some problem!
                  </div>
                </div>
                <div className="dragArea row">
                  <div className="col-lg-12">
                    <p className="mbr-text mbr-fonts-style align-center mb-4 display-7">
                      {" "}
                      Exclusive offers in your inbox
                    </p>
                  </div>
                  <div
                    className="col-lg-4 col-md-12 col-sm-12 form-group mb-3"
                    data-for="name"
                  >
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      data-form-field="name"
                      className="form-control"
                      id="name-form9-s"
                    />
                  </div>
                  <div
                    data-for="email"
                    className="col-lg-4 col-md-12 col-sm-12 form-group mb-3"
                  >
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      data-form-field="email"
                      className="form-control"
                      id="email-form9-s"
                    />
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 mbr-section-btn align-center">
                    <button type="submit" className="btn btn-primary display-4">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section
        data-bs-version="5.1"
        className="share3 cid-toFeL5XW8r"
        id="share3-o"
      >
        <div className="container">
          <div className="media-container-row">
            <div className="col-12">
              <h3 className="mbr-section-title align-center mb-3 mbr-fonts-style display-7">
                <strong>Follow Us!</strong>
              </h3>
              <div className="social-list align-center">
                <a
                  className="iconfont-wrapper bg-facebook m-2 "
                  target="_blank"
                  href="#f"
                >
                  <span className="socicon-facebook socicon"></span>
                </a>
                <a
                  className="iconfont-wrapper bg-twitter m-2"
                  href="#t"
                  target="_blank"
                >
                  <span className="socicon-twitter socicon"></span>
                </a>
                <a
                  className="iconfont-wrapper bg-instagram m-2"
                  href="#i"
                  target="_blank"
                >
                  <span className="socicon-instagram socicon"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        data-bs-version="5.1"
        className="footer3 cid-toFoNl52T8"
        id="footer3-t"
      >
        <div className="container">
          <div className="media-container-row align-center mbr-white">
            <div className="row social-row">
              <div className="social-list align-right pb-2">
                <div className="soc-item">
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="socicon-twitter socicon mbr-iconfont mbr-iconfont-social"></span>
                  </a>
                </div>
                <div className="soc-item">
                  <a
                    href="https://www.facebook.com/pages"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="socicon-facebook socicon mbr-iconfont mbr-iconfont-social"></span>
                  </a>
                </div>
                <div className="soc-item">
                  <a
                    href="https://www.youtube.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="socicon-youtube socicon mbr-iconfont mbr-iconfont-social"></span>
                  </a>
                </div>
                <div className="soc-item">
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="socicon-instagram socicon mbr-iconfont mbr-iconfont-social"></span>
                  </a>
                </div>
              </div>
            </div>
            <div className="row row-copirayt">
              <p className="mbr-text mb-0 mbr-fonts-style mbr-white align-center display-7">
                © Copyright 2023-Stone-Dip. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LandingPage;
