import React, { useEffect, useState } from "react";
import "./ProductDetails.css";
import { useParams } from "react-router-dom";
import * as axios from "../Axios/Axios";
import { v4 as uidv4 } from "uuid";
import { apiUrl } from "../config";

const ProductDetails = () => {
    const { id } = useParams();
    const [post, setPost] = useState({});
    const [images, setImages] = useState([]);
    const [thumbnail, setThumbnail] = useState(null);
    const [activeThumb, setActiveThumb] = useState(0);
    // const [rating, setRating] = useState(0);

    useEffect(() => {
       
        axios.post(`api/product/ItemViewed/${id}`);

        axios.get(`api/Product/${id}`, undefined, (response) => {
            setPost(response.data);
            var img = JSON.parse(response.data.image);
            // var qrCode = JSON.parse(post?.qrCode);
            setImages(img);
            setThumbnail(img[0]);
        });
    }, []);

    var changeImage = (e, image, index) => {
        setThumbnail(image);
        var current = document.getElementsByClassName(
            "slideshow-thumbnails active"
        );
        current[0].className = current[0].className.replace(" active", "");
        setActiveThumb(index);
    };

    return (
        <>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-5">
                        <div className="main-img">
                            <div id="lens"></div>

                            <div id="slideshow-items-container">
                                <img
                                    className="slideshow-items active"
                                    src={`${apiUrl}/${thumbnail}`}
                                    alt=""
                                />
                            </div>

                            <div id="result"></div>
                            <div
                                style={{
                                    marginLeft: "16px",
                                    marginRight: "16px",
                                }}
                            >
                                <div className="row">
                                    {images.map((image, index) => (
                                        <img
                                            key={uidv4()}
                                            className={`slideshow-thumbnails ${
                                                index == activeThumb
                                                    ? "active"
                                                    : ""
                                            }`}
                                            src={`${apiUrl}/${image}`}
                                            onClick={(e) => {
                                                //setActive and change Image
                                                changeImage(e, image, index);
                                            }}
                                            alt=""
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="main-description px-2">
                            <div className="category text-bold">
                                {post?.brand}
                            </div>
                            <div className="product-title text-bold my-3">
                                {post?.model}
                            </div>

                            <div className="price-area">
                                {post?.qrCode != null ? (
                                    <>
                                        <img
                                            src={`${apiUrl}/${post?.qrCode}`}
                                            style={{ width: "300px" }}
                                    alt=""
                                    />
                                        <span style={{ marginLeft: "30px" }}>
                                            Scan to browse from phone
                                        </span>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                        <div className="product-details my-4">
                            <p className="details-title text-color mb-1">
                                Product Details
                            </p>
                            {post?.name != null ? (
                                <p>
                                    <span className="fw-bold">Name:</span>
                                    {post?.name}
                                </p>
                            ) : (
                                <></>
                            )}
                            {post?.category != null ? (
                                <p>
                                    <span className="fw-bold">Category: </span>
                                    {post?.category}
                                </p>
                            ) : (
                                <></>
                            )}

                            {post?.url != null ? (
                                <p>
                                    <span className="fw-bold">Url: </span>
                                    <a href={post?.url} target="_blank" rel="noreferrer">
                                        {post?.url}
                                    </a>
                                </p>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductDetails;
