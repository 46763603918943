/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./PostCard.css";
import * as axios from "../Axios/Axios";
import { apiUrl } from "../config";
// import { Box, Modal, Typography } from "@mui/material";

const PostCard = ({ post, setSelectedProduct, setOpenModal, setOpenViewModal }) => {
  return (
    <div className="mb-3 post-card text-center">
      {post.boostProfile === "Yes" ? (
        <div className="ribbon">
          <div className="card-wrap">
            <span className="ribbon6">Ad</span>
          </div>
        </div>
      ) : (
        <></>
      )}
      <a
        // href={`/product-detail/${post.id}`}
        data-bs-toggle="modal"
        data-bs-target="#productViewModal"
        style={{ textDecoration: "none" }}
        onClick={() => {
          axios.post(`api/product/ItemViewed/${post?.id}`);
          setSelectedProduct(post);
          setOpenViewModal(true)
        }}
      >
        <div className="product-img">
          {post?.image && (
            <img src={`${apiUrl}/${JSON.parse(post?.image)[0]}`} alt="Avatar" />
          )}
        </div>
      </a>
      <div
        className="product-card-body"
        style={{ borderRadius: "0px 0px 10px 10px" }}
      >
        <h6 className="brandName">
          <b>
            <a href={post?.url} target="_blank" rel="noreferrer">
              {post?.name}
            </a>
          </b>
        </h6>
        <p className="brandName">
          <b>{post?.category}</b>
        </p>

        <a
          // data-bs-toggle="modal"
          // data-bs-target={"#shareModal"}
          className="btn-cart"
          onClick={() => {
            // setShareUrl(post?.url);
            setSelectedProduct(post);
            setOpenModal(true);
          }}
          style={{ margin: "0" }}
        >
          Share
        </a>
      </div>
    </div>
  );
};

export default PostCard;
