import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import * as axios from "../Axios/Axios";

const style = {
  label: {
    color: "#555",
    marginBottom: "5px",
    marginTop: "10px",
  },
  cardTitle: {
    color: "#555",
    fontSize: "30px",
    fontWeight: "700",
  },
  title: {
    color: "#555",
    fontSize: "20px",
    fontWeight: "700",
  },
  radio: {
    marginLeft: "20px",
    height: "12px",
  },
};

const Feedback = () => {
  const handleChange = (e, formProps) => {
    formProps.setFieldValue(e.target.name, e.target.value);
  };

  return (
    <div className="r-width" style={{ margin: "auto", marginTop: "60px" }}>
      <h5 className="text-left font-weight-bold">Feedback!</h5>
      <Formik
        enableReinitialize
        initialValues={{}}
        validationSchema={Yup.object({
          name: Yup.string().required("Required"),
          details: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          axios.post("api/feedback", values, undefined, (response) => {
            console.log(response);
            if (response.success) {
              alert("Thank you for your feedback!");
            } else {
              alert(response.data.message);
            }
          });
        }}
      >
        {(formProps) => (
          <Form noValidate>
            <div className="form-group">
              <label style={style.label}>Full Name *</label>
              <input
                name="name"
                type="text"
                required
                className="form-control"
                onChange={(e) => handleChange(e, formProps)}
              />
              <span className="text-danger">{formProps.errors.name}</span>
            </div>

            <div className="form-group">
              <label style={style.label}>Email</label>
              <input
                name="email"
                type="text"
                required
                className="form-control"
                onChange={(e) => handleChange(e, formProps)}
              />
              <span className="text-danger">{formProps.errors.username}</span>
            </div>

            <div className="form-group">
              <label style={style.label}>Feedback *</label>
              <textarea
                name="details"
                type="text"
                className="form-control"
                required
                rows={8}
                onChange={(e) => handleChange(e, formProps)}
              ></textarea>
              <span className="text-danger">{formProps.errors.details}</span>
            </div>

            <input
              type="submit"
              className="form-control btn btn-dark btn-sm mt-3 bg-dark text-white"
              value="Submit"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Feedback;
