import React, { useState } from "react";
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import { Email } from '@material-ui/icons';
import { Form, Formik } from "formik";
import * as yup from "yup";
import * as axios from "../Axios/Axios";
import * as keys from "../Axios/keys";
import * as storage from "../Axios/storage";
import { useNavigate } from "react-router-dom";

const style = {
    card: {
        padding: "20px",
        height: "450px",
        boxShadow: "#00000033 0px 4px 8px 0px, #00000030 0px 6px 20px 0px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "100px",
    },
    label: {
        color: "#555",
        marginBottom: "5px",
        marginTop: "10px",
    },
    cardTitle: {
        color: "#555",
        fontSize: "30px",
        fontWeight: "700",
    },
    title: {
        color: "#555",
        fontSize: "20px",
        fontWeight: "700",
    },
    radio: {
        marginLeft: "20px",
    },
    submitBtn:{
        width: "100%"
    }
};

const SignIn = () => {
    const history = useNavigate();
    const [errMsg, setErrMsg] = useState("");

    const handleChange = (e, formProps) => {
        formProps.setFieldValue(e.target.name, e.target.value);
    };

    return (
        <div className="card login-card" style={style.card}>
            <h4 className="text-left font-weight-bold">Sign in!</h4>
            <Formik
                enableReinitialize
                initialValues={{
                    username: "",
                    password: "",
                }}
                validationSchema={yup.object({
                    username: yup.string().required("Required"),
                    password: yup.string().required("Required"),
                })}
                onSubmit={(values) => {
                    axios.post(
                        "api/account/authenticate",
                        values,
                        undefined,
                        (response) => {
                            storage.removeState(keys.LOGGED_IN_USER);
                            storage.saveState(keys.LOGGED_IN_USER, response);
                            history("/home");
                        },
                        (error) => {
                            if (error.response.status === 401) {
                                setErrMsg("Username or Password in invalid!");
                            } else {
                                setErrMsg(
                                    "Something Went wrong! Please try again letter"
                                );
                            }
                        }
                    );
                }}
            >
                {(formProps) => (
                    <Form>
                        <div className="form-group">
                            <label style={style.label}>Email</label>
                            <input
                                name="username"
                                type="text"
                                required
                                className="form-control"
                                onChange={(e) => handleChange(e, formProps)}
                            />
                            <span className="text-danger">
                                {formProps.errors.username}
                            </span>
                        </div>
                        <div className="form-group">
                            <label style={style.label}>Password</label>
                            <input
                                name="password"
                                type="password"
                                required
                                className="form-control"
                                onChange={(e) => handleChange(e, formProps)}
                            />
                            <span className="text-danger">
                                {formProps.errors.password}
                            </span>
                        </div>

                        <p className="text-danger mb-3">{errMsg}</p>
                        <input
                            type="submit"
                            className="btn btn-dark"
                            style={style.submitBtn}
                            value="Sign In"
                        />

                        {/* <Button
                                variant="contained"
                                color="primary"
                            >
                                Forgot Password
                            </Button> */}
                        <br />
                        <a href="/signup" className="text-dark">Not an User? Register</a>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SignIn;
