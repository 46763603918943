import React, { useEffect, useState } from "react";
import * as axios from "../Axios/Axios";
import { v4 as uidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import { apiUrl } from "../config";

const style = {
    editButton: {
        cursor: "pointer",
        fontSize: "15px",
        marginLeft: "10px",
    },
};

const BatchDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [batch, setBatch] = useState([]);

    var loadData = () => {
        axios.get(`api/Batch/${id}`, undefined, (response) => {
            setBatch(response.data);
        });
    };

    useEffect(() => {
        if (id !== null) {
            loadData();
        }
    }, [id]);

    return (
        <div className="container mt-5">
            <h3 className="text-center mb-3 font-weight-bold">Batch</h3>
            <div>
                <h4>
                    Batch: {batch?.name}{" "}
                </h4>
                <table className="table table-responsible table-striped table-bordered">
                    <thead className="thead">
                        <tr>
                            <th>Sl</th>
                            <th>Category</th>
                            <th>Name</th>
                            <th>Image</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {batch?.products &&
                            batch.products.map((item, index) => (
                                <tr key={uidv4()}>
                                    <td>{index + 1}</td>
                                    <td>{item?.product?.category}</td>
                                    <td><a href={item?.product?.url} target="_blank" rel="noreferrer">{item?.product?.name} ooo</a></td>
                                    <td>
                                        <img
                                            src={`${apiUrl}/${
                                                JSON.parse(
                                                    item?.product?.image
                                                )[0]
                                            }`}
                                            style={{ width: "100px" }}
                                            alt=""
                                        />
                                    </td>
                                    <td>
                                        <i
                                            className="fa fa-eye"
                                            style={style.editButton}
                                            onClick={() => {
                                                navigate(
                                                    `/product-detail/${item?.productId}`
                                                );
                                            }}
                                        ></i>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BatchDetails;
