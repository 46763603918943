import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isAuthorized } from './Utils';

const PrivateRoute = ({ redirectPath = '/signIn' }) => {
    if (!isAuthorized()) {
        return <Navigate to={redirectPath} replace />;
    }

    return <Outlet />;
};

export default PrivateRoute;