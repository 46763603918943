import axios from "axios";
import { apiUrl as DEVELOPMENT_URL } from "../config";
import * as keys from "./keys";
import * as storage from "./storage";

const createConfig = () => {
    var data = storage.loadState(keys.LOGGED_IN_USER);
    if (data !== undefined) {
        return {
            headers: {
                Authorization: `Bearer ${data.token}`,
                "Access-Control-Allow-Origin": "*",
                "access-control-expose-headers": "*",
                "access-control-allow-methods":
                    "GET, HEAD, POST, PUT, PATCH, DELETE, OPTIONS",
            },
        };
    } else {
        return undefined;
    }
};

const createConfig2 = () => {
    var data = storage.loadState(keys.LOGGED_IN_USER);
    if (data !== undefined) {
        return {
            headers: {
                Authorization: `Bearer ${data.token}`,
                "Access-Control-Allow-Origin": "*",
                "access-control-expose-headers": "*",
                "access-control-allow-methods":
                    "GET, HEAD, POST, PUT, PATCH, DELETE, OPTIONS",
                "Content-Type": "multipart/form-data",
            },
        };
    } else {
        return undefined;
    }
};

// var DEVELOPMENT_URL = apiUrl

// fetch('manifest.json', {
//     headers: {
//         'Content-Type': 'application/json',
//         'Accept': 'application/json'
//     }
// }
// ).then(function (response) {
//     return response.json();
// }).then(function (menifest) {
//     DEVELOPMENT_URL = menifest.api_url;
// });

export const filterNull = (data) => {
    if (data !== null && data !== undefined) {
        for (var i = 0; i < data.length; i++) {
            for (var property in data[i]) {
                if (
                    data[i][property] === "null" ||
                    data[i][property] === null
                ) {
                    data[i][property] = "";
                }
            }
        }
    }
    return data;
};

export const get = (url, setData, callBack) => {
    axios
        .get(`${DEVELOPMENT_URL}/${url}`, createConfig())
        .then((response) => {
            if (response.status === 200 && setData !== undefined) {
                setData({
                    data: filterNull(response.data.data),
                    pageconfig: response.data.pageconfig,
                    success: response.data.success,
                    message: response.data.message,
                    statusCode: response.data.statusCode,
                });
            }

            if (callBack !== undefined) {
                callBack(response.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

export const post = (url, postData, setData, callBack, failed) => {
    axios
        .post(`${DEVELOPMENT_URL}/${url}`, postData, createConfig())
        .then((response) => {
            if (response.status === 200 && setData !== undefined) {
                setData({
                    data: filterNull(response.data.data),
                    pageconfig: response.data.pageconfig,
                    success: response.data.success,
                    message: response.data.message,
                    statusCode: response.data.statusCode,
                });
            }

            if (callBack !== undefined) {
                callBack(response.data);
            }
        })
        .catch((error) => {
            if (failed !== undefined) {
                failed(error);
            }
            console.log(error);
        });
};

export const put = (url, data, setData, callBack) => {
    axios
        .put(`${DEVELOPMENT_URL}/${url}`, data, createConfig())
        .then((response) => {
            if (response.status === 200 && setData !== undefined) {
                setData({
                    data: filterNull(response.data.data),
                    pageconfig: response.data.pageconfig,
                    success: response.data.success,
                    message: response.data.message,
                    statusCode: response.data.statusCode,
                });
            }

            if (callBack !== undefined) {
                callBack(response.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

export const deleteRequest = (url, callBack) => {
    axios
        .delete(`${DEVELOPMENT_URL}/${url}`, createConfig())
        .then((response) => {
            if (callBack !== undefined) {
                callBack(response.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

export const postFormData = (url, postData, files, callBack) => {
    const formData = new FormData();
    // formData.append("model", JSON.stringify(postData));

    for (var property in postData) {
        formData.append(
            property,
            postData[property] == null ? "" : postData[property]
        );
    }

    console.log(files);
    for (let i = 0; i < files.length; i++) {
        formData.append("images", files[i]);
    }

    axios
        .post(`${DEVELOPMENT_URL}/${url}`, formData, createConfig2())
        .then((response) => {
            if (callBack !== undefined) {
                callBack(response);
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

export const putFormData = (url, postData, files, callBack) => {
    const formData = new FormData();
    // formData.append("model", JSON.stringify(postData));

    for (var property in postData) {
        formData.append(
            property,
            postData[property] == null ? "" : postData[property]
        );
    }

    console.log(files);
    if (files?.length > 0) {
        for (let i = 0; i < files.length; i++) {
            console.log(files[i]);
            formData.append("images", files[i]);
        }
    }

    axios
        .put(`${DEVELOPMENT_URL}/${url}`, formData, createConfig2())
        .then((response) => {
            if (callBack !== undefined) {
                callBack(response);
            }
        })
        .catch((error) => {
            console.log(error);
        });
};
