import * as axios from "../Axios/Axios";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import { Box, Modal, Typography } from "@mui/material";
import { useCallback } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ShareModal = ({ openModal, setOpenModal, selectedProduct }) => {
  const handleCopyToClipboard = useCallback(async () => {
    const textToCopy = selectedProduct?.url || "";

    if (!textToCopy) {
      console.error("No text to copy");
      return;
    }

    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log("Text copied to clipboard:", textToCopy);
      alert("Text copied to clipboard: " + textToCopy);
    } catch (error) {
      console.error("Copying text to clipboard failed:", error);

      // Fallback for browsers that don't support the Clipboard API
      try {
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);

        console.log("Text copied to clipboard (fallback):", textToCopy);
        alert("Text copied to clipboard: " + textToCopy);
      } catch (fallbackError) {
        console.error(
          "Copying text to clipboard is not supported:",
          fallbackError
        );
        alert("Copying text to clipboard failed");
      }
    }
  }, [selectedProduct]);

  var shareItem = () => {
    if (window.confirm("Are you sure")) {
      axios.post(`api/Product/itemShared/${selectedProduct?.id}`);
      return true;
    }
    return false;
  };

  return (
    <Modal
      //   className="modal fade"
      id={"productModal"}
      //   aria-labelledby="productLabel"
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Share with others
        </Typography>
        <div id="modal-modal-description" className="modal-body">
          {/* {detectDeviceType() === "Desktop" ? ( */}
          <i
            title="Copy to clipboard"
            className="fa fa-copy"
            style={{
              background: "#c4c6d1",
              padding: "8px",
              borderRadius: "50%",
              marginRight: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (shareItem()) {
                // navigator.clipboard.writeText(selectedProduct.url)
                handleCopyToClipboard();
              }
            }}
          />
          {/* ) : null} */}

          <FacebookShareButton
            title="Share on Facebook"
            url={selectedProduct?.url}
            style={{ marginRight: "10px" }}
            onClick={(e) => {
              shareItem();
            }}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <FacebookMessengerShareButton
            title="Share on Messenger"
            url={selectedProduct?.url}
            style={{ marginRight: "10px" }}
            onClick={(e) => {
              shareItem();
            }}
          >
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>
          <WhatsappShareButton
            title="Share on WhatsApp"
            url={selectedProduct?.url}
            style={{ marginRight: "10px" }}
            onClick={(e) => {
              shareItem();
            }}
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <TwitterShareButton
            title="Share on Twitter"
            url={selectedProduct?.url}
            style={{ marginRight: "10px" }}
            onClick={(e) => {
              shareItem();
            }}
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <TelegramShareButton
            title="Share on Telegram"
            url={selectedProduct?.url}
            style={{ marginRight: "10px" }}
            onClick={(e) => {
              shareItem();
            }}
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          <LinkedinShareButton
            title="Share on Linkedin"
            url={selectedProduct?.url}
            style={{ marginRight: "10px" }}
            onClick={(e) => {
              shareItem();
            }}
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <PinterestShareButton
            title="Share on Pinterest"
            url={selectedProduct?.url}
            style={{ marginRight: "10px" }}
            onClick={(e) => {
              shareItem();
            }}
          >
            <PinterestIcon size={32} round />
          </PinterestShareButton>
          <ViberShareButton
            title="Share on Viber"
            url={selectedProduct?.url}
            style={{ marginRight: "10px" }}
            onClick={(e) => {
              shareItem();
            }}
          >
            <ViberIcon size={32} round />
          </ViberShareButton>
          <EmailShareButton
            title="Share by Email"
            url={selectedProduct?.url}
            style={{ marginRight: "10px" }}
            onClick={(e) => {
              shareItem();
            }}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
          <RedditShareButton
            title="Share by Reddit"
            url={selectedProduct?.url}
            style={{ marginRight: "10px" }}
            onClick={(e) => {
              shareItem();
            }}
          >
            <RedditIcon size={32} round />
          </RedditShareButton>
        </div>
      </Box>
    </Modal>
  );
};

export default ShareModal;
