import React, { useEffect, useState } from "react";
import * as axios from "../Axios/Axios";
import { v4 as uidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
// import * as storage from "../Axios/storage";
// import * as keys from "../Utils/keys";
import { Form, Formik } from "formik";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { apiUrl } from "../config";

const style = {
  editButton: {
    cursor: "pointer",
    fontSize: "15px",
    marginLeft: "10px",
  },
};

const BatchCode = () => {
  const navigate = useNavigate();
  // const user = storage.loadState(keys.LOGGED_IN_USER);
  const [products, setProducts] = useState([]);
  const [batches, setBatches] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleChange = (e, formProps) => {
    formProps.setFieldValue(e.target.name, e.target.value);
  };

  var loadData = () => {
    axios.get("api/Batch", undefined, (response) => {
      setBatches(response.data);
    });
    axios.get("api/Product/OwnProducts", undefined, (response) => {
      setProducts(response.data);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="container mt-5">
      <h3 className="text-center mb-3 font-weight-bold">Batches</h3>
      <a
        href="#batchMadal"
        data-bs-toggle="modal"
        data-bs-target="#batchModal"
        className="btn btn-dark btn-sm"
      >
        <i className="fa fa-plus"></i> Add
      </a>
      <div className="col-4 mb-4">
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          placeholder="Search by batch name"
          onChange={(e) => {
            setSearchKeyword(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              axios.get(
                `api/Batch/Search/${searchKeyword}`,
                undefined,
                (response) => {
                  setBatches(response.data);
                }
              );
            }
          }}
        />
      </div>
      {batches &&
        batches.map((batch) => (
          <div key={uidv4()}>
            <h4>
              Batch: {batch?.name}{" "}
              <span
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={() => {
                  if (window.confirm("Are you sure?")) {
                    axios.post(`api/Batch/share/${batch.id}`, null, () => {
                      alert("Done!");
                    });
                  }
                }}
              >
                <i
                  className="fa fa-share-alt-square mr-1"
                  title="Generate batch for Item"
                  style={style.editButton}
                />{" "}
                {"  "}
                Generate batch as item
              </span>
              <i
                className="fa fa-trash text-danger"
                title="Delete Batch"
                style={style.editButton}
                onClick={() => {
                  if (window.confirm("Are you sure?")) {
                    axios.deleteRequest(`api/Batch/${batch.id}`, () => {
                      loadData();
                    });
                  }
                }}
              ></i>
            </h4>
            <table className="table table-responsible table-striped table-bordered">
              <thead className="thead">
                <tr>
                  <th>Sl</th>
                  <th>Category</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {batch?.products &&
                  batch.products.map((item, index) => (
                    <tr key={uidv4()}>
                      <td>{index + 1}</td>
                      <td>{item?.product?.category}</td>
                      <td>{item?.product?.name}</td>
                      <td>
                        <img
                          src={`${apiUrl}/${
                            JSON.parse(item?.product?.image)[0]
                          }`}
                          style={{ width: "100px" }}
                          alt=""
                        />
                      </td>
                      <td>
                        <i
                          className="fa fa-edit"
                          style={style.editButton}
                          onClick={() => {
                            navigate(`/edit-post/${item?.productId}`);
                          }}
                        ></i>
                        <i
                          className="fa fa-trash text-danger"
                          style={style.editButton}
                          title="Delete Product"
                          onClick={() => {
                            if (window.confirm("Are you sure?")) {
                              axios.deleteRequest(
                                `api/Product/${item?.productId}`,
                                () => {
                                  loadData();
                                }
                              );
                            }
                          }}
                        ></i>
                        <i
                          className="fa fa-times text-danger"
                          style={style.editButton}
                          title="Remove from batch"
                          onClick={() => {
                            if (window.confirm("Are you sure?")) {
                              axios.deleteRequest(
                                `api/Batch/DeleteBatchProduct/${item?.id}`,
                                () => {
                                  loadData();
                                }
                              );
                            }
                          }}
                        ></i>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ))}

      <div
        className="modal fade"
        id="batchModal"
        aria-labelledby="batchModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content"  style={{overflow: 'scroll', height: "700px", width: "550px"}}>
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Formik
                enableReinitialize
                initialValues={{}}
                onSubmit={(values) => {
                  if (values.batchName === "" || values.batchName === null) {
                    alert("Please enter a batch name");
                  }
                  let arr = [];
                  products.forEach((product) => {
                    if (product.isChecked === true) {
                      arr.push({
                        productId: product.id,
                      });
                    }
                  });
                  axios.post(
                    "api/Batch",
                    {
                      name: values.batchName,
                      products: arr,
                    },
                    undefined,
                    (response) => {
                      if (response.success) {
                        alert("Batch created Successfully!");
                        window.location.reload();
                      } else {
                        alert("Sorry! Something went wrong!");
                      }
                    }
                  );
                }}
              >
                {(formProps) => (
                  <Form>
                    <TextField
                      name="batchName"
                      label="Batch Name"
                      // variant="outlined"
                      margin="normal"
                      fullWidth
                      onChange={(e) => handleChange(e, formProps)}
                      InputLabelProps={{
                        shrink: true,
                        className: "font-700",
                      }}
                      error={formProps.errors.batchName ? true : false}
                      helperText={formProps.errors.batchName}
                    />
                    <table className="table table-responsible table-striped table-bordered">
                      <thead className="thead">
                        <tr>
                          <th>Select</th>
                          <th>Category</th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products?.map((item, index) => (
                          <tr key={uidv4()}>
                            <td>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="product"
                                    checked={item.isChecked}
                                    onChange={(e) => {
                                      // handleChange(e, formProps);
                                      if (e.target.checked === true) {
                                        let a = [...products];
                                        let pi = products.findIndex(
                                          (p) => p.id === item.id
                                        );
                                        a[pi].isChecked = true;
                                        setProducts(a);
                                      } else {
                                        let a = [...products];
                                        let pi = products.findIndex(
                                          (p) => p.id === item.id
                                        );
                                        a[pi].isChecked = false;
                                        setProducts(a);
                                      }
                                    }}
                                  />
                                }
                              />
                            </td>
                            <td>{item.category}</td>
                            <td>{item.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <input
                      type="submit"
                      className="btn btn-primary btn-sm"
                      value="Submit"
                      disabled={formProps.isSubmitting}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchCode;
