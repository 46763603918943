import React, { useState } from "react";
// import AdvertisementCard from '../Components/AdvertisementCard';
import Sidebar from "./Sidebar";
// import guiter from '../images/guiter.jpg';
// import iphone from '../images/iphone.jpg';

const Layout = ({ component: Component, ...rest }) => {
  const [show, setShow] = useState(true);

  var Sidebar_open = () => {
    console.log(show);
    if (show) {
      // document.getElementById("main-container").style.marginLeft = "275px";
      document.getElementById("sidebar").style.width = "250px";
      document.getElementById("sidebar").style.display = "block";
      setShow(false);
    } else {
      // document.getElementById("main-container").style.marginLeft = "0px";
      document.getElementById("sidebar").style.width = "0";
      document.getElementById("sidebar").style.display = "none";
      setShow(true);
    }
  };
  return (
    <>
      <Sidebar setShow={setShow} />

      <div id="main-container">
        <button
          id="sidebar-btn"
          className="w3-button bg-dark text-white w3-xlarge "
          onClick={() => {
            Sidebar_open();
          }}
        >
          &#9776;
        </button>
        <div className="w3-container">
          <div className="row">
            <Component {...rest} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
