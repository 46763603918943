import React from "react";
import "./Home.css";
import * as keys from "../Axios/keys";
import * as storage from "../Axios/storage";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ setShow }) => {
  var user = storage.loadState(keys.LOGGED_IN_USER);
  var navigate = useNavigate();
  const Logout = () => {
    storage.removeState(keys.LOGGED_IN_USER);
    navigate("/");
  };

  return (
    <div id="sidebar" className="w3-sidebar w3-light-grey w3-bar-block menu">
      <span
        className="closebtn"
        onClick={() => {
          document.getElementById("sidebar").style.width = "0";
          document.getElementById("sidebar").style.display = "none";
          setShow(true);
        }}
      >
        <i className="fa-solid fa-xmark"></i>
      </span>
      <h3 className="w3-bar-item mt-5">
        {/* <img src={logo} className="App-logo" alt="name" />  */}
        <a href="/">
          <span className="text-ellipsis text-purple-800 logo-font">
            Scan-Savy
          </span>
        </a>
      </h3>
      <a href="/scan-item" className="w3-bar-item w3-button">
        <span className="i">
          <i className="fa fa-magnifying-glass"></i>
        </span>
        Scan Item
      </a>
      <a href="/home" className="w3-bar-item w3-button">
        <span className="i">
          <i className="fa fa-list"></i>
        </span>
        Item Collection
      </a>
      <a href="/create-post" className="w3-bar-item w3-button">
        <span className="i">
          <i className="fa fa-qrcode"></i>
        </span>
        Item Generation
      </a>
      <a href="/batches" className="w3-bar-item w3-button">
        <span className="i">
          <i className="fa fa-object-group"></i>
        </span>
        Batch Creator
      </a>

      <a href="/analytics" className="w3-bar-item w3-button">
        <span className="i">
          <i className="fa fa-bar-chart"></i>
        </span>
        Admin & Analytics
      </a>

      <a href="/history" className="w3-bar-item w3-button">
        <span className="i">
          <i className="fa fa-history"></i>
        </span>
        History
      </a>
      <a href="/profile" className="w3-bar-item w3-button">
        <span className="i">
          <i className="fa-regular fa-user"></i>
        </span>
        Profile
      </a>
      <a href="/feedback" className="w3-bar-item w3-button">
        <span className="i">
          <i className="fa-regular fa-comment"></i>
        </span>
        Feedback
      </a>
      {user !== undefined && user != null && user.role === "Admin" ? (
        <a href="/admin" className="w3-bar-item w3-button">
          <span className="i">
            <i className="fa-solid fa-hammer"></i>
          </span>
          Admin
        </a>
      ) : (
        <></>
      )}
      {!user ? (
        <a href="/signIn" className="w3-bar-item w3-button">
          <span className="i">
            <i className="fa-solid fa-sign-in"></i>
          </span>
          Login
        </a>
      ) : (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => Logout()}
          className="w3-bar-item w3-button"
        >
          <span className="i">
            <i className="fa-solid fa-sign-out"></i>
          </span>
          Logout
        </div>
      )}
    </div>
  );
};

export default Sidebar;
