import React, { useEffect, useState } from "react";
import * as axios from "../Axios/Axios";
import { v4 as uidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../config";

const style = {
  editButton: {
    cursor: "pointer",
    fontSize: "15px",
    marginLeft: "10px",
  },
  radio: {
    marginLeft: "20px",
    height: "12px",
  },
};

const Analytics = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [searchBy, setSearchBy] = useState({});

  const handleChange = (e) => {
    var obj = {
      ...searchBy,
    };

    obj[e.target.name] = e.target.value;
    setSearchBy(obj);
    // formProps.setFieldValue(e.target.name, e.target.value);
  };

  var loadData = () => {
    axios.get("api/Product/OwnProducts", undefined, (response) => {
      setProducts(response.data);
    });
  };

  const search = () => {
    axios.get(
      `api/product/search/${searchBy.name ?? "all"}/${
        searchBy.sortBy ?? "share"
      }`,
      undefined,
      (response) => {
        setProducts(response.data);
      }
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="container mt-5">
      <h3 className="text-center mb-3 font-weight-bold">Items</h3>

      <div className="row">
        <div className="col-md-4 col-xs-12">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Search"
            onChange={(e) => handleChange(e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                search();
              }
            }}
          />
        </div>
        <div className="col-md-5 col-xs-12 mb-5">
          {" "}
          <div className="form-group mb-2">
            <label>Sort By</label> {"  "}
            <input
              style={style.radio}
              type="radio"
              name="sortBy"
              value="scan"
              onChange={(e) => handleChange(e)}
            />{" "}
            Scan
            <input
              style={style.radio}
              type="radio"
              name="sortBy"
              value="share"
              onChange={(e) => handleChange(e)}
            />{" "}
            Shared
            <button
              className="btn btn-sm btn-dark ml-3"
              onClick={() => {
                search();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      <table className="table table-responsible table-striped table-bordered">
        <thead className="thead">
          <tr>
            <th>Sl</th>
            <th>Category</th>
            <th>Name</th>
            <th>Scanned</th>
            <th>Shared</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products?.map((item, index) => (
            <tr key={uidv4()}>
              <td>{index + 1}</td>
              <td>{item.category}</td>
              <td>
                <a href={item?.url} target="_blank" rel="noreferrer">
                  {item.name}
                </a>
              </td>
              <td>{item.totalScanned}</td>
              <td>{item.totalShared}</td>
              <td>
                {item?.image && (
                  <img
                    src={`${apiUrl}/${JSON.parse(item?.image)[0]}`}
                    style={{ width: "100px" }}
                    alt=""
                  />
                )}
              </td>
              <td>
                <i
                  className="fa fa-edit"
                  style={style.editButton}
                  onClick={() => {
                    navigate(`/edit-post/${item?.id}`);
                  }}
                ></i>
                <i
                  className="fa fa-trash text-danger"
                  style={style.editButton}
                  onClick={() => {
                    if (window.confirm("Are you sure?")) {
                      axios.deleteRequest(`api/Product/${item.id}`, () => {
                        loadData();
                      });
                    }
                  }}
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Analytics;
