import React, { useEffect, useState } from "react";
import * as axios from "../Axios/Axios";
import { v4 as uidv4 } from "uuid";
import { apiUrl } from "../config";

const style = {
  editButton: {
    cursor: "pointer",
    fontSize: "15px",
    marginLeft: "10px",
  },
  radio: {
    marginLeft: "20px",
    height: "12px",
  },
};

const Hisotry = () => {
  // const navigate = useNavigate();
  // const user = storage.loadState(keys.LOGGED_IN_USER);
  const [histories, setHistories] = useState([]);
  const [searchBy, setSearchBy] = useState({});

  const handleChange = (e) => {
    var obj = {
      ...searchBy,
    };
    obj[e.target.name] = e.target.value;
    setSearchBy(obj);
    // formProps.setFieldValue(e.target.name, e.target.value);
  };

  var loadData = () => {
    axios.get("api/Product/history", undefined, (response) => {
      setHistories(response.data);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="container mt-5">
      <h3 className="text-center mb-3 font-weight-bold">Items</h3>

      <div className="row mb-3">
        <div className="col-md-4 col-xs-12">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Search"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>

      <table className="table table-responsible table-striped table-bordered">
        <thead className="thead">
          <tr>
            <th>Sl</th>
            <th>Category</th>
            <th>Name</th>
            <th>Image</th>
            <th>Entry Time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {histories?.map((item, index) => (
            <tr key={uidv4()}>
              <td>{index + 1}</td>
              <td>{item?.product?.category}</td>
              <td>
                <a href={item?.product?.url} target="_blank" rel="noreferrer">
                  {item?.product?.name}
                </a>
              </td>
              <td>
                {item?.product?.image && (
                  <img
                    src={`${apiUrl}/${JSON.parse(item?.product?.image)[0]}`}
                    style={{ width: "60px" }}
                    alt=""
                  />
                )}
              </td>
              <td>{item?.entryDateTime}</td>
              <td>
                <a
                  href={`/product-detail/${item?.product?.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fa fa-eye text-info"
                    style={style.editButton}
                  ></i>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Hisotry;
