import React from "react";
import { apiUrl } from "../config";
import { Box, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ProductModal = ({ openModal, setOpenModal, post }) => {
  console.log(post);
  return (
    <div
      className="modal fade"
      id="productViewModal"
      aria-labelledby="productLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content" style={{ height: "430px" }}>
          <div className="modal-header">Scan to browse from phone</div>
          <h3>{post?.name}</h3>
          {post?.qrCode && (
            <img
              src={`${apiUrl}/${post?.qrCode}`}
              style={{ width: "300px", margin: "auto" }}
              alt=""
            />
          )}
        </div>
      </div>
    </div>

    // <Modal
    //   className="modal fade"
    //   id="productViewModal"
    //   open={true}
    //   onClose={() => {
    //     setOpenModal(false);
    //   }}
    // >
    //   <Box sx={style}>
    //     <Typography id="modal-modal-title" variant="h6" component="h2">
    //       Scan to browse from phone
    //     </Typography>
    //     <div id="modal-modal-description" className="modal-body">
    //       <h3>{post?.name}</h3>
    //       {post?.qrCode && (
    //         <img
    //           src={`${apiUrl}/${post?.qrCode}`}
    //           style={{ width: "300px", margin: "auto" }}
    //           alt=""
    //         />
    //       )}
    //     </div>
    //   </Box>
    // </Modal>
  );
};

export default ProductModal;
