import { useEffect, useState } from "react";
import { useHtml5QrCodeScanner } from "react-html5-qrcode-reader";
import * as axios from "../Axios/Axios";
import { v4 as uidv4 } from "uuid";

const ScannedItem = () => {
  const [newPost, setNewPost] = useState({});
  const [qrText, setQrText] = useState(null);

  const { Html5QrcodeScanner } = useHtml5QrCodeScanner(
    "https://unpkg.com/html5-qrcode"
  );

  useEffect(() => {
    if (Html5QrcodeScanner) {
      // Creates anew instance of `HtmlQrcodeScanner` and renders the block.
      let html5QrcodeScanner = new Html5QrcodeScanner(
        "reader",
        { fps: 10, qrbox: { width: 250, height: 250 } },
        /* verbose= */ false
      );
      html5QrcodeScanner.render(
        (data) => {
          setQrText(data);
          var post = newPost;
          post.description = data;
          setNewPost(post);
        },
        (err) => console.log("err ->", err)
      );
    }
  }, [Html5QrcodeScanner]);

  var [products, setProducts] = useState([]);

  var loadData = () => {
    axios.get("api/ScannedItem", undefined, (response) => {
      setProducts(response.data);
    });
  };

  useEffect(() => {
    loadData();
  }, [setProducts]);

  var onChangeHandler = (event) => {
    var post = newPost;
    post[event.target.name] = event.target.value;
    setNewPost(post);
  };

  var onsubmit = () => {
    axios.post("api/ScannedItem", newPost, undefined, (response) => {
      console.log(response.data);
      if (response.success) {
        alert("done");
        setNewPost({});
        setQrText(null);
        loadData();
      }
    });
  };

  return (
    <div className="container">
      <h3 className="mt-5">Scan QR Code</h3>
        <div id="reader"></div>
      <div className="col-md-12 col-xs-12">
        <div>
          <div className="form-group mb-2">
            <label htmlFor="brand">Title</label>
            <input
              type="text"
              className="form-control"
              id="title"
              name="title"
              onChange={(e) => onChangeHandler(e)}
            />
          </div>
          <div className="form-group mb-2">
            <label htmlFor="brand">Scanned Text</label>
            <textarea
              id="description"
              name="description"
              className="form-control"
              onChange={(e) => onChangeHandler(e)}
              defaultValue={qrText}
            ></textarea>
          </div>
          <input
            type="submit"
            className="btn btn-dark btn-sm bg-dark text-white"
            onClick={() => {
              onsubmit();
            }}
          />
        </div>
      </div>

      <div className="mt-5">
        <h3>List</h3>
        <table className="table table-responsible table-striped table-bordered">
          <thead className="thead">
            <tr>
              <th>Sl</th>
              <th>Title</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products?.map((item, index) => (
              <tr key={uidv4()}>
                <td>{index + 1}</td>
                <td>{item.title}</td>
                <td>{item.description}</td>
                <td>
                  {/* <i
                                                    className="fa fa-edit"
                                                    style={style.editButton}
                                                    onClick={() => {
                                                        navigate(
                                                            `/edit-post/${item.id}/${user.user_id}`
                                                        );
                                                    }}
                                                ></i> */}
                  <i
                    className="fa fa-trash text-danger"
                    // style={style.editButton}
                    onClick={() => {
                      if (window.confirm("Are you sure?")) {
                        axios.deleteRequest(
                          `api/ScannedItem/${item.id}`,
                          () => {
                            axios.get(
                              "api/ScannedItem",
                              undefined,
                              (response) => {
                                loadData();
                              }
                            );
                          }
                        );
                      }
                    }}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScannedItem;
