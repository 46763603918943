import React, { useEffect, useState } from "react";
import * as axios from "../Axios/Axios";
import { v4 as uidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import * as storage from "../Axios/storage";
import * as keys from "../Utils/keys";

const style = {
    editButton: {
        cursor: "pointer",
        fontSize: "15px",
        marginLeft: "10px"
      }
}

const SellerHistory = () => {
    const navigate = useNavigate();
    const user = storage.loadState(keys.LOGGED_IN_USER);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        axios.get("api/Product/SellerProducts", undefined, (response) => {
            setProducts(response.data);
        });
    }, [])


    return (
        <div className="container mt-5">
            <h3 className="text-center mb-3 font-weight-bold">
                Seller History
            </h3>
            <table className="table table-responsible table-striped table-bordered">
                <thead className="thead">
                    <tr>
                        <th>Sl</th>
                        <th>Brand</th>
                        <th>Model</th>
                        <th>Price</th>
                        <th>Damage</th>
                        <th>Slang Local?</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((item, index) => (
                        <tr key={uidv4()}>
                            <td>{index + 1}</td>
                            <td>{item.brand}</td>
                            <td>{item.model}</td>
                            <td>{item.price}</td>
                            <td>{item.damage}</td>
                            <td>{item.slangLocal}</td>
                            <td>
                                <i
                                    className="fa fa-edit"
                                    style={style.editButton}
                                    onClick={()=>{
                                        navigate(`/edit-post/${item.id}/${user.user_id}`);
                                    }}
                                ></i>
                                <i
                                    className="fa fa-trash text-danger"
                                    style={style.editButton}
                                    onClick={()=>{
                                        if(window.confirm("Are you sure?")){
                                            axios.deleteRequest(`api/Product/${item.id}`, () =>{
                                                axios.get("api/Product/SellerProducts", undefined, (response) => {
                                                    setProducts(response.data);
                                                });
                                            })
                                        }
                                    }}
                                ></i>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default SellerHistory;