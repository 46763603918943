import React, { useEffect, useState } from "react";
import "./Home.css";
import * as axios from "../Axios/Axios";
import { apiUrl } from "../config";
import { useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";

const style = {
    label: {
        color: "#555",
        marginBottom: "5px",
        marginTop: "10px",
    },
    cardTitle: {
        color: "#555",
        fontSize: "30px",
        fontWeight: "700",
    },
    title: {
        color: "#555",
        fontSize: "20px",
        fontWeight: "700",
    },
    radio: {
        marginLeft: "20px",
    },
};

const EditPost = () => {
    const { id } = useParams();
    const [model, setModel] = useState({ name: "", url: "", category: "" });
    const [qrCode, setQrCode] = useState(null);

    useEffect(() => {
        axios.get(`api/Product/${id}`, undefined, (response) => {
            setModel(response.data);
        });
    }, [id]);

    const savePost = (values) => {
        var data = {
            id: values.id,
            name: values.name,
            url: values.url,
            category: values.category,
        };

        const fileInput = document.getElementById("image");

        axios.putFormData("api/Product", data, fileInput?.files, (response) => {
            if (response.data.success) {
                alert("done");
                setQrCode(response.data.data);
            } else {
                alert(response.data.message);
            }
        });
    };

    return (
        <div className="mt-1">
            <div className="container">
                <div className="row">
                    <div
                        className="col-sm-7 col-xs-12"
                        style={{ marginTop: "30px" }}
                    >
                        <h4
                            className="card-title text-center"
                            style={style.cardTitle}
                        >
                            Edit
                        </h4>
                        <Formik
                            initialValues={model}
                            enableReinitialize
                            // validationSchema={{}}
                            onSubmit={(
                                values,
                                { setSubmitting, resetForm }
                            ) => {
                                savePost(values);
                            }}
                        >
                            {(formprops) => (
                                <Form>
                                    <div className="form-group mb-2">
                                        <label
                                            style={style.label}
                                            htmlFor="brand"
                                        >
                                            Name of Item
                                        </label>
                                        <Field
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                        />
                                    </div>
                                    <div
                                        className="form-group mb-2"
                                        style={{ display: "block" }}
                                    >
                                        <label
                                            style={style.label}
                                            htmlFor="brand"
                                        >
                                            Item URL (it's webpage)
                                        </label>
                                        <Field
                                            type="text"
                                            className="form-control"
                                            id="url"
                                            name="url"
                                        />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label
                                            style={style.label}
                                            htmlFor="model"
                                        >
                                            Item Category
                                        </label>
                                        <Field
                                            type="text"
                                            className="form-control"
                                            id="category"
                                            name="category"
                                        />
                                    </div>

                                    <div className="form-group  mb-2">
                                        <label
                                            style={style.label}
                                            htmlFor="image"
                                        >
                                            Image
                                        </label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="image"
                                            name="image"
                                            multiple
                                        />
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="submit"
                                            className="form-control mt-2 btn btn-dark text-white bg-dark"
                                            style={{ marginLeft: "0" }}
                                            value="Submit"
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className="col-sm-5 col-xs-12" style={{ marginTop: "30px" }}>
                        {qrCode != null ? (
                            <img
                                src={`${apiUrl}/${qrCode}`}
                                style={{ width: "300px" }}
                                alt=""
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditPost;
