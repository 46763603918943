// import {
//   History,
//   LocationCity,
//   Message,
//   PendingActions,
//   Phone,
//   StarBorder,
// } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
// import img1 from "../images/dip.jpg";
import cover from "../images/cover2.jpeg";
// import { Rating, Typography } from "@mui/material";
import * as axios from "../Axios/Axios";
// import { useParams } from "react-router-dom";
// import { Form, Formik } from "formik";
// import * as Yup from "yup";
// import { useNavigate } from "react-router-dom";
import { apiUrl } from "../config";

var style = {
  coverPhoto: {
    background: `url(${cover})`,
    width: "100%",
    height: "300px",
  },
  profile: {
    marginTop: "-52px",
  },
  contact: {
    // background: "#f8f8f8",
    padding: "20px",
  },
  name: {
    marginTop: "-52px",
    textAlign: "left",
    marginLeft: "210px",
  },
  editButton: {
    cursor: "pointer",
    fontSize: "15px",
  },
  sellerCard: {
    height: "150px",
    textAlign: "center",
    background: "#DFE7FE",
    borderRadius: "10px",
    cursor: "pointer",
    boxShadow: "#00000000 0px 2px 3px 0px, #00000030 0px 3px 10px 0px",
  },
  postCard: {
    height: "150px",
    textAlign: "center",
    background: "#B6F3F5",
    borderRadius: "10px",
    cursor: "pointer",
    boxShadow: "#00000000 0px 2px 3px 0px, #00000030 0px 3px 10px 0px",
  },
  cardHeader: {
    paddingTop: "10px",
    fontSize: "17px",
    fontWeight: "bold",
  },
  icon: {
    fontSize: "90px",
  },
};

const Profile = () => {
//   const navigate = useNavigate();
//   const [attachment, setAttachment] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios.get(`api/User/Profile`, undefined, (response) => {
      setUser(response.data);
      console.log(response.data);
    });
  }, []);

  return (
    <>
      <div className="text-center mt-3">
        <div style={style.coverPhoto}></div>
        {/* <img src={cover} alt="" style={style.coverPhoto} /> */}
        <div className="profile-sec">
          <img
            src={`${apiUrl}/${user?.photo}`}
            alt=""
            className="profile-pic"
            style={style.profile}
          />
        </div>
        <h2 style={style.name}>
          {user?.name}{" "}
          <a href="/edit-profile">
            <i className="fa fa-edit" style={style.editButton}></i>
          </a>
        </h2>
      </div>
      <div className="row mt-5" style={style.contact}>
        <div className="col-md-3 col-xs-12">
          <p>
            <b>Email: </b>
            <span style={{ color: "#0088ff" }}>{user?.username}</span>
          </p>
          <p>
            <b>Business Type:</b> {user?.businessType}
          </p>
          <p>
            <b>Store Name:</b> {user?.storeName}
          </p>
          <p>
            <b>Store Address:</b> {user?.storeAddress}
          </p>
          <p>
            <b>Web Link:</b> {user?.webLink}
          </p>
          {/* <Typography
                        align="left"
                        variant="body2"
                        fontWeight="bold"
                        fontSize={25}
                    >
                        {" "}
                        4.5
                        <Rating
                            name={`rate-1`}
                            value={4.5}
                            precision={0.5}
                            emptyIcon={<StarBorder fontSize="inherit" />}
                            // onChange={e => handleRating(e.target.value)}
                        />
                    </Typography> */}
        </div>
        <div className="col-9">
          {/* <div className="row">
                        <div className="col-7">
                            <div style={style.sellerCard}>
                                <p style={style.cardHeader}>Batch Code</p>
                                <a href="/batches">
                                    <History style={style.icon} />
                                </a>
                            </div>
                        </div>
                        <div className="col-5">
                            <div style={style.postCard}>
                                <p style={style.cardHeader}>1scanned Item</p>
                                <a href="/scan-item">
                                    <PendingActions style={style.icon} />
                                </a>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </>
  );
};

export default Profile;
